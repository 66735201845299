import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./MarketplaceDetailsPage.module.css";
import MarketplaceDetailsSection from "./partials/MarketplaceDetailsSection/MarketplaceDetailsSection";
import { COMPANY_EMAIL } from "../../constants/constants";

function MarketplaceDetailsPage() {
  const { solutionId } = useParams();
  const [solutionData, setSolutionData] = useState({});
  const [marketplaceData, setMarketplaceData] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://encyc-cdn.s3.amazonaws.com/public_asset/json/marketplace.json"
        );
        const data = await response.json();
        setMarketplaceData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (solutionId && !isEmpty(marketplaceData))
      for (let index = 0; index < marketplaceData.length; index++) {
        for (
          let indexCategory = 0;
          indexCategory < marketplaceData[index].SubCategories.length;
          indexCategory++
        ) {
          for (
            let indexSubCategory = 0;
            indexSubCategory <
            marketplaceData[index].SubCategories[indexCategory].Solutions
              .length;
            indexSubCategory++
          ) {
            const { id } =
              marketplaceData[index].SubCategories[indexCategory].Solutions[
                indexSubCategory
              ];
            if (id.toString() === solutionId) {
              setSolutionData(
                marketplaceData[index].SubCategories[indexCategory].Solutions[
                  indexSubCategory
                ]
              );
              break;
            }
          }
        }
      }
  }, [solutionId, marketplaceData]);

  if (isEmpty(solutionData)) return null;

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.logoContainer}>
          <img src={solutionData.Partner.Logo} className={styles.logo} alt="" />
        </div>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{solutionData.Name}</span>
          <span className={styles.summary}>{solutionData.Summary}</span>
          <div style={{ display: "flex" }}>
            <span className={styles.vendor}>By: </span>
            <span
              className={styles.vendor}
              onClick={() => window.open(solutionData.Partner?.Link)}
            >
              {solutionData.Partner.Vendor}
            </span>
          </div>
          <div className={styles.tagContainer}>
            {solutionData.Tags.map((tag) => (
              <div className={styles.tag}>{tag}</div>
            ))}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button1}
            onClick={() => window.open(`mailto:${COMPANY_EMAIL}`)}
          >
            Buy
          </button>
          <button
            className={styles.button2}
            onClick={() => window.open(solutionData?.SolutionDesignLink)}
          >
            Solution Design
          </button>
        </div>
      </div>
      <MarketplaceDetailsSection solutionData={solutionData} />
    </div>
  );
}

export default MarketplaceDetailsPage;
