import React from "react";

import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Image } from "react-bootstrap";
import { RiArrowRightLine } from "react-icons/ri";

import styles from "./WhatWeDo.module.css";

import { home } from "../../data";
import whoWeAre from "../../images/Homepage-images/WhoWeAre.png";

const WhatWeDo = () => {
  return (
    <div className={`${styles.whatwedo} `}>
      <h2>{home.whatWeDo.title}</h2>
      <Row className={styles["whatwedo__cards"]}>
        {home.whatWeDo.data.map((data, i) => (
          <Col
            className={styles["whatwedo__cards-content"]}
            key={i}
            xs={12}
            md={4}
            sm={6}
          >
            <Card style={{ width: "auto" }}>
              <Card.Body>
                <Card.Title className="mb-3">
                  <h4>{data.title}</h4>
                </Card.Title>
                <Card.Text>{data.text}</Card.Text>
              </Card.Body>
            </Card>
            <div className={styles.box}>
              <div className={styles.borderLeft}></div>
            </div>
          </Col>
        ))}
      </Row>

      <Row className={styles.whoweare}>
        <Col md={6} sm={12} className={styles["whoweare__contents"]}>
          <h3>{home.whoWeAre.sectionTitle}</h3>
          <h2>{home.whoWeAre.title}</h2>
          <p>{home.whoWeAre.text}</p>
          <Link to="/services">
            <Button size="lg" variant="outline-dark">
              Services <RiArrowRightLine />
            </Button>
          </Link>
        </Col>

        <Col md={6} sm={12} className={styles.image}>
          <Image fluid rounded src={whoWeAre} alt="image" />
        </Col>
      </Row>
    </div>
  );
};

export default WhatWeDo;
