import React, { useRef } from "react";
// import Axios from "axios";
import { Col, Row, Container } from "react-bootstrap";

import "./Partners.css";

// import { countries } from "../../data";
import { BriefText } from "../Cardlinks";

import Brief from "../Brief/Brief";
import { EncycPartners } from "../Encycpartner";
import PartnerCard from "../TechnologyPartners/PartnerCard";
import ContactUsFooter from "../contactUsFooter/ContactUsFooter";

const Partners = () => {
  // const [companyName, setCompanyName] = useState("");
  // const [website, setWebsite] = useState("");
  // const [address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  // const [zipCode, setZipCode] = useState("");
  // const [country, setCountry] = useState("");
  // const [partnerProgram, setPartnerProgram] = useState("none");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [message, setMessage] = useState("");
  // const [successful, setSuccessful] = useState(false);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const partnerForm = `${process.env.REACT_APP_PUBLIC_REQUEST}/partner-form`;

  //   await Axios.post(partnerForm, {
  //     companyName,
  //     website,
  //     address,
  //     city,
  //     zipCode,
  //     country,
  //     partnerProgram,
  //     firstName,
  //     lastName,
  //     email,
  //     phone,
  //     message,
  //   })
  //     .then(() => setSuccessful(true))
  //     .catch((err) => console.error(err));
  // };

  const tech = useRef(null);
  const sw = useRef(null);
  const edu = useRef(null);
  const event = useRef(null);
  const rural = useRef(null);

  const scrollToSection = (elementRef) => {
    var headerOffset = 55;
    var elementPosition = elementRef.current.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div>
        <Brief BriefText={BriefText[0]} />

        {/* Partner Bar      */}

        <div className={"partners-nav"}>
          <div className="wrapper-1200">
            <ul className="ulPartnerBar">
              <li
                className="liPartnerBar"
                onClick={() => {
                  scrollToSection(tech);
                }}
              >
                {EncycPartners[0].PartnerType}
              </li>
              <li
                className="liPartnerBar"
                onClick={() => {
                  scrollToSection(sw);
                }}
              >
                {EncycPartners[1].PartnerType}
              </li>
              <li
                className="liPartnerBar"
                onClick={() => {
                  scrollToSection(edu);
                }}
              >
                {EncycPartners[2].PartnerType}
              </li>
              <li
                className="liPartnerBar"
                onClick={() => {
                  scrollToSection(event);
                }}
              >
                {EncycPartners[3].PartnerType}
              </li>
              <li
                className="liPartnerBar"
                onClick={() => {
                  scrollToSection(rural);
                }}
              >
                {EncycPartners[4].PartnerType}
              </li>
            </ul>
          </div>
        </div>

        {/* Partner Body */}

        <div
          className="wrapper"
          // style={{
          //   backgroundColor: partner.background,
          // }}
        >
          <div className="techPartner" ref={tech}>
            <hr className="hr-tiny" />
            <h2 className="partnerHeading">{EncycPartners[0].PartnerType}</h2>
            <p className="partnerSubHeading">{EncycPartners[0].Objective}</p>
            <button className="partnerButton">
            <a href="mailto:admin@encycdata.com"> BECOME A {EncycPartners[0].PartnerType} </a>
            </button>
            <Container fluid>
              <Row className="cardRow" xs={2} md={4} xl={4}>
                {EncycPartners[0].Partners.map((Partners) => (
                  <Col style={{ padding: "10px" }}>
                    <PartnerCard link={Partners} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div
            className="techPartner"
            style={{
              backgroundColor: "white",
            }}
            ref={sw}
          >
            <hr className="hr-tiny" />
            <h2 className="partnerHeading">{EncycPartners[1].PartnerType}</h2>
            <p className="partnerSubHeading">{EncycPartners[1].Objective}</p>
            <button className="partnerButton">
              <a href="mailto:admin@encycdata.com"> BECOME A {EncycPartners[1].PartnerType} </a>
            </button>
            <Container fluid>
              <Row className="cardRow" xs={2} md={4} xl={4}>
                {EncycPartners[1].Partners.map((Partners) => (
                  <Col style={{ padding: "10px" }}>
                    <PartnerCard link={Partners} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div className="techPartner" ref={edu}>
            <hr className="hr-tiny" />
            <h2 className="partnerHeading">{EncycPartners[2].PartnerType}</h2>
            <p className="partnerSubHeading">{EncycPartners[2].Objective}</p>
            <button className="partnerButton">
            <a href="mailto:admin@encycdata.com"> BECOME A {EncycPartners[2].PartnerType} </a>
            </button>
            <Container fluid>
              <Row className="cardRow" xs={2} md={4} xl={4}>
                {EncycPartners[2].Partners.map((Partners) => (
                  <Col style={{ padding: "10px" }}>
                    <PartnerCard link={Partners} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div
            className="techPartner"
            style={{
              backgroundColor: "white",
            }}
            ref={event}
          >
            <hr className="hr-tiny" />
            <h2 className="partnerHeading">{EncycPartners[3].PartnerType}</h2>
            <p className="partnerSubHeading">{EncycPartners[3].Objective}</p>
            <button className="partnerButton">
            <a href="mailto:admin@encycdata.com"> BECOME A {EncycPartners[3].PartnerType} </a>
            </button>
            <Container fluid>
              <Row className="cardRow" xs={2} md={4} xl={4}>
                {EncycPartners[3].Partners.map((Partners) => (
                  <Col style={{ padding: "10px" }}>
                    {console.log(Partners.Logo)}
                    <PartnerCard link={Partners} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div className="techPartner" ref={rural}>
            <hr className="hr-tiny" />
            <h2 className="partnerHeading">{EncycPartners[4].PartnerType}</h2>
            <p className="partnerSubHeading">{EncycPartners[4].Objective}</p>
            <button className="partnerButton">
            <a href="mailto:admin@encycdata.com"> BECOME A {EncycPartners[4].PartnerType} </a>
            </button>
            <Container fluid>
              <Row className="cardRow" xs={2} md={4} xl={4}>
                {EncycPartners[4].Partners.map((Partners) => (
                  <Col style={{ padding: "10px" }}>
                    <PartnerCard link={Partners} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <ContactUsFooter />

      {/* <div className={styles["partners__form-container"]}>
        <Form onSubmit={handleSubmit} className={styles.form}>
          <Row>
            <Col className={styles["form__col"]} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Email*:</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>First Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Company Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setCompanyName(e.target.value)}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid company name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Website*:</Form.Label>
                <Form.Control
                  onChange={(e) => setWebsite(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone:</Form.Label>
                <Form.Control
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address:</Form.Label>
                <Form.Control
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>City:</Form.Label>
                <Form.Control
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>ZIP Code:</Form.Label>
                <Form.Control
                  onChange={(e) => setZipCode(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country*:</Form.Label>
                <Form.Select onChange={(e) => setCountry(e.target.value)}>
                  <option defaultValue={"select"}>Select Country</option>
                  {countries.map((country) => (
                    <option key={country}>{country}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Partner Program*:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  required
                  onChange={(e) => {
                    setPartnerProgram(e.target.value);
                  }}
                >
                  <option value="none" selected disabled hidden>
                    Select Program
                  </option>
                  <option value="service delivery part">
                    {" "}
                    Event Management Program{" "}
                  </option>
                  <option value="service delivery part">
                    {" "}
                    Service Delivery Program{" "}
                  </option>
                  <option value="Technology Program">
                    Technology Solutions Program
                  </option>
                  <option value="Freelancer">
                    Education Solutions Program
                  </option>
                  <option value="Freelancer">
                    Rural and Economic Empowerment
                  </option>
                </Form.Control>
                {partnerProgram === "none" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Please select a partner program.
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  onChange={(e) => setMessage(e.target.value)}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Col>
          </Row>
          <p style={{ color: "red", fontSize: "12px" }}>
            {!successful && "Fill the required details with mark '*'"}
          </p>

          <div className={styles["partners__button"]}>
            <Button
              type="submit"
              disabled={successful}
              variant="outline-dark"
              className="mt-5"
            >
              {successful
                ? "Your data is submitted successfully. Our representatives will validate your data and will contact you. Thank you!"
                : "Register"}
            </Button>
          </div>
        </Form>
      </div> */}
    </div>
  );
};

export default Partners;
