import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/home/Home";
import Company from "./components/company/Company";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Partners from "./components/partners/Partners";
import Products from "./components/products/Products";
import Research from "./components/research/Research";
import Services from "./components/services/Services";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NewsFlash from "./components/navbar/NewsFlash";
import Careers from "./components/careers/Careers";
import ContactUsForm from "./components/ContactUsForm/ContactUsForm";
import Blogs from "./components/Blogs/Blogs";
import Events from "./components/Events/Events";
import Testimonials from "./components/Testimonials/Testimonials";
import Marketplace from "./components/Marketplace/Marketplace";
import MarketplaceDetailsPage from "./components/MarketplaceDetailsPage/MarketplaceDetailsPage";

const App = () => {
  return (
    <>
      <NewsFlash />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="services" element={<Services />} />
        <Route path="research" element={<Research />} />
        <Route path="partners" element={<Partners />} />
        <Route path="aboutus" element={<Company />} />
        <Route path="careers" element={<Careers />} />
        <Route path="marketplace">
          <Route index element={<Marketplace />} />
          <Route path=":solutionId" element={<MarketplaceDetailsPage />} />
        </Route>
        <Route path="contactus" element={<ContactUsForm />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="events" element={<Events />} />
        <Route path="testimonials" element={<Testimonials />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
