import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import "./PartnerCard.css";
import { Modal } from "react-bootstrap";

export default function PartnerCard({ link }) {
  const [modalShow, setModalShow] = React.useState(false);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {link.Partnership}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4
            style={{
              display: "inline",
            }}
          >
            Solutions :{" "}
            {link.Solutions.map((solution, index) => (
              <h4
                style={{
                  display: "inline",
                }}
              >
                {(index ? ", " : " ") + solution}
              </h4>
            ))}
          </h4>
          <h4>
            Services :{"   "}
            {link.Services.map((solution, index) => (
              <h4
                style={{
                  display: "inline",
                }}
              >
                {(index ? ", " : " ") + solution}
              </h4>
            ))}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <div>
      <Card
        sx={{ Width: "18vw", Height: "18vw" }}
        onClick={() => setModalShow(true)}
      >
        <CardMedia
          style={{
            height: 150,
            padding: "10px",
            objectFit: "contain",
          }}
          className="cardMedia"
          component="img"
          alt={link.name}
          image={link.Logo}
        />
        <CardActions
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            size="small"
            className="learnMore"
            onClick={(e) => {
              e.stopPropagation();
              openInNewTab(link.LearnMore);
            }}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

// import { Button } from "@mui/material";
// import "./PartnerCard.css";

// function PartnerCard({ link }) {
//   console.log(link);

//   return (
//     <Button className="cards" disableElevation>
//       <img className="image" src={link.iconlink} alt="BigCo Inc. logo" />
//     </Button>

//     //   <Card border="light" className="cards" style={{ borderRadius: "5px" }}>
//     //     <Card.Img src={link.iconlink} />
//     //     {/* <Card.Text className="cards" onClick={() => {}}>
//     //       Learn More
//     //     </Card.Text> */}
//     //   </Card>
//   );
// }

// export default PartnerCard;
