import React, { useEffect, useState } from "react";
import styles from "./MarketplaceListContainer.module.css";
import MarketplaceSubCategoryContainer from "../MarketplaceSubCategoryContainer/MarketplaceSubCategoryContainer";

function MarketplaceListContainer({ selectedCategory, marketplaceData }) {
  return (
    <div className={styles.container}>
      {marketplaceData?.map((category) => {
        return (
          (category.MainCategory === selectedCategory ||
            selectedCategory === "All") && (
            <div>
              <div className={styles.filterTitle}>{category.MainCategory}</div>
              {category.SubCategories.map((subCategories) => (
                <MarketplaceSubCategoryContainer
                  subCategoryData={subCategories}
                />
              ))}
            </div>
          )
        );
      })}
    </div>
  );
}

export default MarketplaceListContainer;
