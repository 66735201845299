import React from "react";
import { Row, Col, Image } from "react-bootstrap";

// IMAGES
import ourStory from "../../images/Company/OurStory.jpg";
import ourVision from "../../images/Company/OurVision.JPG";
import ourMission from "../../images/Company/OurMission.jpg";
import coreValues from "../../images/Company/OurCoreValues.JPG";
import workCulture1 from "../../images/Company/Workculture1.JPG";
import workCulture2 from "../../images/Company/Workculture2.JPG";
import workCulture3 from "../../images/Company/Workculture3.JPG";
import Goals from "../../images/Company/Goals1.JPG";
import contactUs from "../../images/Company/Contactus.JPG";

import OurMission1 from "../../images/Mission-Vision-Profile/OurMission1.jpg";
import OurVision1 from "../../images/Mission-Vision-Profile/OurVision1.jpg";

import styles from "./Company.module.css";

import { company } from "../../data";
import ContactUsFooter from "../contactUsFooter/ContactUsFooter";

const Company = () => {
  return (
    <div style={{ backgroundColor: "#e9f4fd" }}>
      <div className={styles["aboutUsWrapper"]}>
        <div className={`${styles.container} mt-5 `}>
          <Row className={styles["company__card-row"]}>
            <Col>
              <h3>{company.ourStory.title}</h3>
              <p>{company.ourStory.text}</p>
            </Col>
            <Col
              className={`${styles["company__main-img-col"]} ${styles["company__main-img-right"]}`}
            >
              <Image src={ourStory} alt="image" />
            </Col>
          </Row>

          <Row className={styles["company__card-row"]}>
            <Col className={styles["company__main-img-col"]}>
              <Image src={ourVision} alt="image" />
            </Col>
            <Col>
              <h3>
                {company.ourVision.title}{" "}
                <Image
                  style={{ width: "100px", borderRadius: "50%" }}
                  thumbnail
                  src={OurVision1}
                  alt="image"
                />
              </h3>
              <p>{company.ourVision.text}</p>
            </Col>
          </Row>

          <Row className={styles["company__card-row"]}>
            <Col>
              <h3>
                <Image
                  style={{ width: "100px", borderRadius: "50%" }}
                  thumbnail
                  src={OurMission1}
                  alt="image"
                />{" "}
                {company.ourMission.title}
              </h3>
              <p>{company.ourMission.text}</p>
            </Col>
            <Col
              className={`${styles["company__main-img-col"]} ${styles["company__main-img-right"]}`}
            >
              <Image src={ourMission} alt="image" />
            </Col>
          </Row>

          <Row className={styles["company__card-row"]}>
            <Col className={styles["company__main-img-col"]}>
              <Image src={coreValues} alt="image" />
            </Col>
            <Col>
              <h3>Core Values</h3>
              {company.coreValues.map((data, i) => (
                <div key={i}>
                  <li className="mb-2">
                    <strong>{data.title}:</strong> {data.text}
                  </li>
                </div>
              ))}
            </Col>
          </Row>

          <Row className={styles["company__card-row"]}>
            <Col>
              <h3>{company.goal.title}</h3>
              {company.goal.data.map((data, i) => (
                <div key={i}>
                  <li className="mb-2">{data}</li>
                </div>
              ))}
            </Col>
            <Col
              className={`${styles["company__main-img-col"]} ${styles["company__main-img-right"]}`}
            >
              <Image src={Goals} alt="image" />
            </Col>
          </Row>

          <Row className={styles["company__card-row"]}>
            <Col className={styles["company__card-grid-images"]}>
              <Row>
                <Col className={styles["company__grid-img1"]}>
                  <Image src={workCulture2} alt="image" />
                </Col>
              </Row>
              <Row className={styles["company__grid-images-down"]}>
                <Col className={styles["company__grid-img2"]}>
                  <Image src={workCulture1} alt="image" />
                </Col>
                <Col className={styles["company__grid-img3"]}>
                  <Image src={workCulture3} alt="image" />
                </Col>
              </Row>
            </Col>
            <Col>
              <h3>{company.workCulture.title}</h3>
              <p>{company.workCulture.text}</p>
            </Col>
          </Row>
          <Row>
            <Col className={styles["contact__address"]}>
              <h2>Our Offices</h2>
              <br></br>
              <p>
                <h3>India</h3>
                <br />
                <b>Registered Office:</b>
                <br />
                <i>MSME Registration #: UDYAM-TN-20-0046643</i>
                <br />
                5/102, Sitheri Pallakkadu, Attur, Salem, Tamilnadu 636101
                <br />
                Mobile:
                <a href="tel:+91 8807349930 "> +91 8807349930</a>
                <br />
                For General queries:
                <a href="mailto:admin@encycdata.com"> admin@encycdata.com</a>
                <br />
                For Event Marketing:
                <a href="mailto:eventanalysts@encycdata.com">
                  eventanalysts@encycdata.com
                </a>

                <br />
                <br></br>
              <b>CoWorking Space (Bangalore):</b>
              <br />
              No.115 & 116, 4th floor, NR TOWERS, 50 ft Main Road, 8th Cross, Celebrity Paradise Electronic City Phase 1, Bengaluru, Karnataka 560100
              <br />
              Mobile:
                <a href="tel:+91 8807349930 "> +91 8807349930</a>
                <br />
                For General queries:
                <a href="mailto:admin@encycdata.com"> admin@encycdata.com</a>
                <br />

              </p>
            </Col>
            <Col xs={12} md={6}>
              <Image fluid src={contactUs} alt="image" />
            </Col>
          </Row>
        </div>
      </div>
      <ContactUsFooter />
    </div>
  );
};

export default Company;
