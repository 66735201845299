import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { RiCloseLine, RiMenu3Line } from "react-icons/ri";
import logo from "../../images/logo.png";
import styles from "./Navbar.module.css";
import DropDown from "../dropdown/dropdown";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const navCloseOnClick = () => {
    setToggleMenu(false);
  };

  const companyItems = [
    {
      name: "About Us",
      link: "/aboutus",
    },

    //{ name: "Testimonials", link: "/testimonials" },
    { name: "Careers", link: "/careers" },

    /*{
      name: "News and Events",
      link: "/",
  },*/
  ];

  //  const resourceItems = [
  //     { name: "Whitepaper", link: "/" },
  //     { name: "Solution Brief", link: "/" },
  //     { name: "Webinars", link: "/" },
  //     { name: "Case Studies", link: "/" },
  //     { name: "Videos", link: "/" },
  //     { name: "Blogs", link: "/" },
  //   ];

  return (
    <div className={styles.navbar}>
      <div>
        <Link className={styles["navbar-links_logo"]} to="/">
          <Image thumbnail src={logo} alt="logo" />
          {/* <h1>Encycdata</h1> */}
        </Link>
      </div>
      <div className={styles["navbar-links"]}>
        <div className={styles["navbar-links_container"]}>
          <p>
            <Link onClick={navCloseOnClick} to="/">
              Home
            </Link>
          </p>
          <p>
            <Link onClick={navCloseOnClick} to="/products">
              Products
            </Link>
          </p>
          <p>
            <Link onClick={navCloseOnClick} to="/services">
              Services
            </Link>
          </p>
          <p>
            <Link onClick={navCloseOnClick} to="/marketplace">
              Marketplace
            </Link>
          </p>
          {/* <p>
            <Link onClick={navCloseOnClick} to="/research">
              Research
            </Link>
          </p> */}
          <p>
            <Link onClick={navCloseOnClick} to="/blogs">
              Blogs
            </Link>
          </p>
          {/* <p>
            <Link onClick={navCloseOnClick} to="/events">
              Events
            </Link>
          </p> */}
          <p>
            <Link onClick={navCloseOnClick} to="/partners">
              Partners
            </Link>
          </p>
          <p style={{ marginLeft: "-0.15rem" }}>
            {/*<DropDown
              title="Resources"
              items={resourceItems}
              navCloseOnClick={navCloseOnClick}
  /> */}
          </p>
          <p
            style={{
              marginLeft: "-0.15rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DropDown
              title="Company"
              items={companyItems}
              navCloseOnClick={navCloseOnClick}
            />
            {/* <VscTriangleDown /> */}
          </p>
          <Button
            size="lg"
            style={{
              marginLeft: "10px",
              backgroundColor: "orange",
              borderColor: "PaleVioletRed",
            }}
            variant="outline-dark"
          >
            <a
              href="https://www.belldiary.com/"
              target="_blank"
              rel="noreferrer"
            >
              Try BellDiary
            </a>
          </Button>
        </div>
      </div>
      <div className={styles["navbar-menu"]}>
        {toggleMenu ? (
          <RiCloseLine
            color="000"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="000"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className={`${styles["navbar-menu_container"]} scale-up-center`}>
            <div className={styles["navbar-menu_container-links"]}>
              <p>
                <Link onClick={navCloseOnClick} to="/">
                  Home
                </Link>
              </p>
              <p>
                <Link onClick={navCloseOnClick} to="/products">
                  Products
                </Link>
              </p>
              <p>
                <Link onClick={navCloseOnClick} to="/services">
                  Services
                </Link>
              </p>
              {/* <p>
                <Link onClick={navCloseOnClick} to="/research">
                  Research
                </Link>
              </p> */}
              <p>
                <Link onClick={navCloseOnClick} to="/partners">
                  Partners
                </Link>
              </p>

              <p>
                <Link onClick={navCloseOnClick} to="/blogs">
                  Blogs
                </Link>
              </p>
              <p>
                <Link onClick={navCloseOnClick} to="/aboutus">
                  Company
                </Link>
              </p>

              <a href="mailto:admin@encycdata.com" style="color:IndianRed">
                <p>Get a Demo</p>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
