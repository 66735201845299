import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProjectNeemImage from "./ProjectNeemImage";
import ProjectNeemText from "./ProjectNeemText";

function ProjectNeem() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8}>
          <ProjectNeemText />
        </Col>
        <Col>
          <ProjectNeemImage />
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectNeem;
