import React, { useState } from "react";
import styles from "./Careers.module.css";
import { FaGlobeAsia } from "react-icons/fa";
import { useEffect } from "react";
import { isEmpty } from "lodash";

const Careers = () => {
  const [selected, setSelected] = useState();
  const [careersData, setCareersData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://encyc-cdn.s3.amazonaws.com/public_asset/json/careers.json"
        );
        const data = await response.json();
        setCareersData(data);
        setSelected(Object.keys(data?.jobs)[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (isEmpty(careersData)) {
    return null;
  }

  return (
    <div>
      <div>
        <div className={`${styles.container} mt-5 section__margin`}>
          <div className={styles["careersWrapper"]}>
            <h1>{careersData.title}</h1>
            <br></br>
            <p>
              {careersData.description}
              <a
                className={styles["linkStyle"]}
                href={`mailto:${careersData.email}`}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "5px" }}
              >
                {careersData.email}
              </a>
            </p>
          </div>

          <div className={styles.btn__group}>
            {Object.keys(careersData.jobs).map((category) => (
              <div
                onClick={() => setSelected(category)}
                className={`${styles.tab} ${
                  selected === category ? styles.active : null
                }`}
              >
                {category.replace("_", " ")}
              </div>
            ))}
          </div>

          <div className={styles.jobs}>
            {careersData.jobs[selected]?.map((job) => {
              const { id, jobTitle, skill, location, description } = job;
              return (
                <div key={id} className={styles.job}>
                  <div className={styles.location}>
                    <div>
                      <FaGlobeAsia />
                    </div>
                    <span>{location}</span>
                  </div>
                  <div className={styles.jobTitle}>{jobTitle}</div>
                  <br />
                  {skill?.length && (
                    <>
                      <div className={styles.subHeadings}>Skills</div>
                      <div className={styles.skill}>
                        {skill?.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </div>
                    </>
                  )}
                  <br />
                  {description?.length && (
                    <>
                      <div className={styles.subHeadings}>Description</div>
                      <div className={styles.skill}>
                        {description?.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
