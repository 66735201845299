import React, { useEffect } from "react";
import styles from "./Marketplace.module.css";
import MarketplaceFilters from "./partials/MarketplaceFilters/MarketplaceFilters";
import MarketplaceListContainer from "./partials/MarketplaceListContainer/MarketplaceListContainer";

function Marketplace() {
  const [selectedCategory, setSelectedCategory] = React.useState("All");
  const [marketplaceData, setMarketplaceData] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://encyc-cdn.s3.amazonaws.com/public_asset/json/marketplace.json"
                  );
        const data = await response.json();
        setMarketplaceData(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className={styles.topSection}>
        <div className={styles.topHeader}>Solutions and Services Marketplace</div>
        <div className={styles.topSubHeader}>
          Encycdata marketplace for innovative technology solutions, offerings
          and consulting services from its partner network.
        </div>
      </div>
      <div className={styles.wrapper}>
        <MarketplaceFilters
          selectedCategory={selectedCategory}
          marketplaceData={marketplaceData}
          setSelectedCategory={setSelectedCategory}
        />
        <MarketplaceListContainer
          selectedCategory={selectedCategory}
          marketplaceData={marketplaceData}
        />
      </div>
    </>
  );
}

export default Marketplace;
