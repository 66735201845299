import React, { useRef } from "react";
import styles from "./MarketplaceDetailsSection.module.css";

function MarketplaceDetailsSection({ solutionData }) {
  const technicalRef = useRef(null);
  const sizingRef = useRef(null);
  const bomRef = useRef(null);
  const servicesRef = useRef(null);
  const referencesRef = useRef(null);

  const handleClickTechnical = () => {
    technicalRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickSizing = () => {
    sizingRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickBom = () => {
    bomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickReferences = () => {
    referencesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const navBarOptions = [
    { id: 1, displayText: "Solution Design", key: "SolutionDesign" },
    {
      id: 2,
      displayText: "Technical Specification",
      key: "TechnicalSpecification",
      handleClick: handleClickTechnical,
    },
    {
      id: 3,
      displayText: "Sizing",
      key: "Sizing",
      handleClick: handleClickSizing,
    },
    {
      id: 4,
      displayText: "BOM",
      key: "BOM",
      handleClick: handleClickBom,
    },
    {
      id: 5,
      displayText: "Services And Support",
      key: "ServicesAndSupport",
      handleClick: handleClickServices,
    },
    {
      id: 6,
      displayText: "References",
      key: "References",
      handleClick: handleClickReferences,
    },
  ];

  return (
    <div>
      <div className={styles.navBar}>
        {navBarOptions.map(({ id, displayText, handleClick }) => (
          <div className={styles.navItem} onClick={handleClick} key={id}>
            {displayText}
          </div>
        ))}
      </div>
      {/* SolutionDesign Section */}
      <div>
        {solutionData[navBarOptions[0].key].Overview && (
          <>
            <div className={styles.subHeading}>Overview</div>
            <span className={styles.para}>
              {solutionData[navBarOptions[0].key].Overview}
            </span>
          </>
        )}
        {solutionData[navBarOptions[0].key].Features && (
          <>
            <div className={styles.subHeading}>Features</div>
            <span className={styles.para}>
              {solutionData[navBarOptions[0].key].Features}
            </span>
          </>
        )}
        {solutionData[navBarOptions[0].key].Architecture && (
          <>
            <div className={styles.subHeading}>Architecture</div>
            <div className={styles.logoContainer}>
              <img
                src={solutionData[navBarOptions[0].key].Architecture}
                className={styles.logo}
                alt=""
              />
            </div>
          </>
        )}
      </div>
      {/* TechnicalSpecification Section */}
      <div ref={technicalRef}>
        <div className={styles.heading}>{navBarOptions[1].displayText}</div>
        {solutionData[navBarOptions[1].key].Hardware?.length && (
          <>
            <div className={styles.subHeading}>Hardware</div>
            <div className={styles.listWrapper}>
              {solutionData[navBarOptions[1].key].Hardware.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
        <div className={styles.subHeading}>Software</div>
        <div className={styles.listWrapper}>
          {solutionData[navBarOptions[1].key].Software.map((item) => (
            <li>{item}</li>
          ))}
        </div>
        <div className={styles.subHeading}>Deployment</div>
        <div className={styles.listWrapper}>
          {solutionData[navBarOptions[1].key].Deployment?.Guidelines?.map(
            (item) => (
              <li>{item}</li>
            )
          )}
        </div>
        <div className={styles.logoContainer}>
          <img
            src={solutionData[navBarOptions[1].key].Deployment?.Link}
            className={styles.logo}
            alt=""
          />
        </div>
      </div>
      {/* Sizing Section */}
      <div ref={sizingRef}>
        <div className={styles.heading}>{navBarOptions[2].displayText}</div>
        {solutionData[navBarOptions[2].key].Guidelines?.length && (
          <>
            <div className={styles.subHeading}>Guidelines</div>
            <div className={styles.listWrapper}>
              {solutionData[navBarOptions[2].key].Guidelines.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
        {solutionData[navBarOptions[2].key].Estimation?.length && (
          <>
            <div className={styles.subHeading}>Estimation</div>
            <div className={styles.listWrapper}>
              {solutionData[navBarOptions[2].key].Estimation.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
        <div className={styles.subHeading}>Reference</div>
        <div className={styles.listWrapper}>
          {solutionData[navBarOptions[2].key].Reference.map((item) => (
            <li>{item}</li>
          ))}
        </div>
      </div>
      {/* BOM Section */}
      <div>
        {solutionData[navBarOptions[3].key].Description && (
          <>
            <div className={styles.subHeading}>Description</div>
            <span className={styles.para}>
              {solutionData[navBarOptions[3].key].Description}
            </span>
          </>
        )}
        {solutionData[navBarOptions[3].key].License && (
          <>
            <div className={styles.subHeading}>License</div>
            <span className={styles.para}>
              {solutionData[navBarOptions[3].key].License}
            </span>
          </>
        )}
        {solutionData[navBarOptions[3].key].Reference && (
          <>
            <div className={styles.subHeading}>Reference</div>
            <span className={styles.para}>
              {solutionData[navBarOptions[3].key].Reference}
            </span>
          </>
        )}
      </div>
      {/* ServicesAndSupport Section */}
      <div ref={servicesRef}>
        <div className={styles.heading}>{navBarOptions[4].displayText}</div>
        {solutionData[navBarOptions[4].key].Services?.length && (
          <>
            <div className={styles.subHeading}>Services</div>
            <div className={styles.listWrapper}>
              {solutionData[navBarOptions[4].key].Services.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
        {solutionData[navBarOptions[4].key].Support?.length && (
          <>
            <div className={styles.subHeading}>Support</div>
            <div className={styles.listWrapper}>
              {solutionData[navBarOptions[4].key].Support.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
      </div>
      {/* References Section */}
      <div ref={referencesRef}>
        <div className={styles.heading}>{navBarOptions[5].displayText}</div>
        {solutionData?.[navBarOptions[5].key]?.Guides?.length && (
          <>
            <div className={styles.subHeading}>Guides</div>
            <div className={styles.listWrapper}>
              {solutionData?.[navBarOptions[5].key]?.Guides?.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </>
        )}
        {solutionData?.[navBarOptions[5].key]?.Documentation?.length && (
          <>
            <div className={styles.subHeading}>Documentation</div>
            <div className={styles.listWrapper}>
              {solutionData?.[navBarOptions[5].key]?.Documentation.map(
                (item) => (
                  <li>{item}</li>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// ServicesAndSupport: {
//   Services: ["Design", "Implementation"],
//   Support: ["24/7"],
// },
// References: {
//   Guides: ["Design", "Implementation"],
//   Documentation: ["24/7"],
// },

export default MarketplaceDetailsSection;
