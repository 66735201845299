import React from "react";
import { Link } from "react-router-dom";
import { Button, Carousel } from "react-bootstrap";

import styles from "./Hero.module.css";

import { home } from "../../data";
import { RiArrowRightLine } from "react-icons/ri";

const Hero = () => {
  return (
    <div className={`${styles.home}`}>
      <Carousel
        indicators={false}
        className={styles["home__carousel"]}
        variant="dark"
      >
        {home.carousel.map((data, i) => (
          <Carousel.Item key={i} className={styles["home__carousel-item"]}>
            <div className={styles["home__carousel-content"]}>
              <p className="mt-2">{data.text}</p>
              <div className={styles["buttonContainer"]}>
                <Link
                  to="/products"
                  style={{
                    paddingRight: "6vw",
                  }}
                >
                  <Button className="mt-2" size="lg" variant="outline-light">
                    Read More
                  </Button>
                </Link>

                {/*
                <Button className="mt-4" size="lg" variant="outline-dark" background="blue" >
                <button type="button" class="btn btn-info btn-rounded">
                <button type="button" class="btn btn-outline-danger btn-rounded" data-mdb-ripple-color="dark">
                */}
                
                <button type="button" class="btn btn-dark btn-rounded">
                  <a href="https://forms.gle/zQYJLYgARJ93BeHe8" target="_blank" rel="noreferrer">
                    Get a Demo <RiArrowRightLine />
                  </a>
                </button>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Hero;
