import React from "react";

import { Link } from "react-router-dom";
import { Row, Col, Image, Button } from "react-bootstrap";
import {
  RiNewspaperLine,
  RiCalendarEventFill,
  RiMacbookLine,
  RiArrowRightLine,
} from "react-icons/ri";

import styles from "./HowWeDo.module.css";

import { home } from "../../data";
import howWeAre from "../../images/Homepage-images/HowWeDo.png";

// const AdditionalCards = ({ date, text, link }) => {
//   return (
//     <Row className={`${styles["howwedo__card"]}`}>
//       <Col className={styles["howwedo__card-content"]}>
//         {/* <h4>~{"\n"}</h4> */}
//         <h4>{date}</h4>
//         <h4>{text}</h4>
//         {link && (
//           <h4>
//             <a rel="noreferrer" target="_blank" href={link}>
//               View
//             </a>
//           </h4>
//         )}
//         <hr />
//       </Col>
//     </Row>
//   );
// };

const Events = ({ date, text, link }) => {
  return (
    <a rel="noreferrer" target="_blank" href={link} style={{ padding: "2px" }}>
      <Row className={styles[("howwedo__card", "howwedo__card-event")]}>
        {/* <Col
          sm={4}
          xs={3}
          md={4}
          lg={3}
          className={styles["howwedo__card-date"]}
        >
          <span>{date.substring(3, 6)}</span>
          <span>{date.substring(0, 3)}</span>
        </Col> */}

        <Col className={styles["howwedo__card-content"]}>
          <h4>{date}</h4>
          <h4>{text}</h4>
          {link ? <h4 style={{ color: "lightskyblue" }}>Register</h4> : ""}
        </Col>
      </Row>
    </a>
  );
};

const HowWeDo = () => {
  // event api

  // const [events, setEvents] = useState([]);
  // const getEvents = async () => {
  //   await Axios.get(`${process.env.REACT_APP_PUBLIC_REQUEST}/events`).then(
  //     (result) => {
  //       setEvents(result.data);
  //     }
  //   );
  // };

  // useEffect(() => {
  //   getEvents();

  //   return () => {
  //     getEvents();
  //   };
  // }, []);

  return (
    <div className={`${styles.container} section__margin`}>
      <Row className={styles.howwedo}>
        <Col md={6} sm={12} className={styles["howwedo__image"]}>
          <Image fluid rounded src={howWeAre} alt="image" />
        </Col>
        <Col md={6} sm={12} className={styles["howwedo__contents"]}>
          <h3>{home.howWeDo.sectionTitle}</h3>
          <h2>{home.howWeDo.title}</h2>
          <p>{home.howWeDo.text}</p>
          <Link to="/products">
            <Button size="lg" variant="outline-dark">
              Products <RiArrowRightLine />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className={styles.additional}>
        <Col md={4} xs={12} sm={6} className={styles.news}>
          <h3>
            <RiNewspaperLine /> {home.news.title}
          </h3>
          {home.news.data.map((news, i) => (
            <Events
              key={i}
              date={news.date}
              link={news.link}
              text={news.text}
            />
          ))}
        </Col>

        <Col md={4} xs={12} sm={6} className={styles.events}>
          <h3>
            <RiCalendarEventFill /> {home.events.title}
          </h3>
          {home.events.data.map((event) => (
            <Events
              key={event._id}
              link={event.link}
              date={event.date}
              text={event.text}
            />
          ))}
        </Col>
        <Col md={4} xs={12} sm={6} className={styles.learn}>
          <h3>
            <RiMacbookLine /> {home.learn.title}
          </h3>
          {home.learn.data.map((learn, i) => (
            <Events key={i} link={learn.link} text={learn.text} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default HowWeDo;
