import React from "react";
import styles from "./MarketplaceSubCategoryContainer.module.css";
import MarketplaceCard from "../MarketplaceCard/MarketplaceCard";

function MarketplaceSubCategoryContainer({ subCategoryData }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{subCategoryData.title}</div>
      <div className={styles.cardsWrapper}>
        {subCategoryData?.Solutions?.map((solution) => (
          <MarketplaceCard cardData={solution} />
        ))}
      </div>
    </div>
  );
}

export default MarketplaceSubCategoryContainer;
