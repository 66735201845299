import React from "react";

function BellDairyImage() {
  const publicUrl = process.env.PUBLIC_URL;
  const iconlink = publicUrl + "belldairy/BellDairyLogo.png";
  return (
    <div>
      <img
        src={iconlink}
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "contain",
          paddingTop: "10vh",
          alignItems: "center",
        }}
        alt="Bell Dairy Logo"
      />
    </div>
  );
}

export default BellDairyImage;
