// 4b9990
import React from "react";
import "./Brief.css";

function Brief({ BriefText }) {
  return (
    <div className="brief">
      <p className="text">{BriefText.heading}</p>
    </div>
  );
}

export default Brief;
