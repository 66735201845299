import { Axios } from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import styles from "../partners/Partners.module.css";

import { countries } from "../../data";
import Brief from "../Brief/Brief";
import { BriefText } from "../Cardlinks";

function ContactUsForm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [partnerProgram, setPartnerProgram] = useState("none");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const partnerForm = `${process.env.REACT_APP_PUBLIC_REQUEST}/partner-form`;

    await Axios.post(partnerForm, {
      companyName,
      website,
      address,
      city,
      zipCode,
      country,
      partnerProgram,
      firstName,
      lastName,
      email,
      phone,
      message,
    })
      .then(() => setSuccessful(true))
      .catch((err) => console.error(err));
  };
  return (
    <div>
      <div className={styles["partners__form-container"]}>
        <Brief BriefText={BriefText[2]} />
        <Form onSubmit={handleSubmit} className={styles.form}>
          <Row>
            <Col className={styles["form__col"]} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Email*:</Form.Label>
                <Form.Control
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>First Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Company Name*:</Form.Label>
                <Form.Control
                  onChange={(e) => setCompanyName(e.target.value)}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid company name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Website*:</Form.Label>
                <Form.Control
                  onChange={(e) => setWebsite(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone:</Form.Label>
                <Form.Control
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address:</Form.Label>
                <Form.Control
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>City:</Form.Label>
                <Form.Control
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>ZIP Code:</Form.Label>
                <Form.Control
                  onChange={(e) => setZipCode(e.target.value)}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country*:</Form.Label>
                <Form.Select onChange={(e) => setCountry(e.target.value)}>
                  <option defaultValue={"select"}>Select Country</option>
                  {countries.map((country) => (
                    <option key={country}>{country}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Partner Program*:</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  required
                  onChange={(e) => {
                    setPartnerProgram(e.target.value);
                  }}
                >
                  <option value="none" selected disabled hidden>
                    Select Program
                  </option>
                  <option value="service delivery part">
                    {" "}
                    Event Management Program{" "}
                  </option>
                  <option value="service delivery part">
                    {" "}
                    Service Delivery Program{" "}
                  </option>
                  <option value="Technology Program">
                    Technology Solutions Program
                  </option>
                  <option value="Freelancer">
                    Education Solutions Program
                  </option>
                  <option value="Freelancer">
                    Rural and Economic Empowerment
                  </option>
                </Form.Control>
                {partnerProgram === "none" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Please select a partner program.
                  </p>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  onChange={(e) => setMessage(e.target.value)}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Col>
          </Row>
          <p style={{ color: "red", fontSize: "12px" }}>
            {!successful && "Fill the required details with mark '*'"}
          </p>

          <div className={styles["partners__button"]}>
            <Button
              type="submit"
              disabled={successful}
              variant="outline-dark"
              className="mt-5"
            >
              {successful
                ? "Your data is submitted successfully. Our representatives will validate your data and will contact you. Thank you!"
                : "Register"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ContactUsForm;
