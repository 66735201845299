import React from "react";
import "./ProjectNeemText.css";

function ProjectNeemText() {
  return (
    <div>
      <div className="WrapperForCloudMarket">
        <br></br>
      <ul className="ulstyle">
        <li className="blue">Project: </li>
        <li className="yellow">Neem-Coir Space</li>
      </ul>
        
        <p className="projectNeem-subheading">
          Many social and collaborative platforms are available to engage and
          communicate with our known circle. World has choice of different cultures
          and traditions. Making new relations and finding interests among them
          and sharing things can betterment next generations.
        </p>
        <ul>
          <li className="default">
            Just feel like you have connected with all your relatives around the world{" "}
          </li>
          <li className="default">
          Children are new roots and can  connect with relatives everywhere{" "}
          </li>
          <li className="default">
          Meet them and plan for get-together, events, tour, etc.{" "}
          </li>
          <li className="default">
          Share your experiences, collaborate and engage{" "}
          </li>
          
        </ul>
      </div>
    </div>
  );
}

export default ProjectNeemText;
