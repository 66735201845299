import React from "react";

import styles from "./Research.module.css";

import { research } from "../../data";

const Research = () => {
  // Dropdown>
  //     <Dropdown.Toggle>
  //       Company{` `} <VscTriangleDown />
  //     </Dropdown.Toggle>

  //     <Dropdown.Menu>
  //       {items.map((item) => {
  //         const { name, link } = item;
  //         return (
  //           <Dropdown.Item>
  //             <Link onClick={navCloseOnClick} to={link}>
  //               {name}
  //             </Link>
  //           </Dropdown.Item>
  //         );
  //       })}
  //     </Dropdown.Menu>
  //   </Dropdown>
  return (
    <div className={`${styles.container} section__margin`}>
      <p>{research.description}</p>

      <h3>{research.ruralAndEconomic.title}</h3>
      {research.ruralAndEconomic.list.map((item, i) => (
        <li key={i}>{item}</li>
      ))}

      <h3>{research.diversityWorkplace.title}</h3>
      {research.diversityWorkplace.list.map((item, i) => (
        <li key={i}>{item}</li>
      ))}

      <h3>{research.multiCloudAdoption.title}</h3>
      {research.multiCloudAdoption.list.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </div>
  );
};

export default Research;
