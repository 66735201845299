import React from "react";
import "./CloudMarketPlace.css";
import Container from "react-bootstrap/Container";

function CloudMarketPlace() {
  return (
    <div>
      <Container>
        <br></br>
        <div className="WrapperForCloudMarket">
        <ul className="ulstyle">
        <li className="blue">Encycdata </li>
        <li className="yellow">Cloud Marketplace</li>
      </ul>
                    
          <p className="cloudMarket-subheading">
            A marketplace catering towards complete infrastructure and data center options and solutions across the world
            to simplify and optimize cloud adoption and migration journey
          </p>
          <ul>
          <li className="default">
              Define infrastructure, cloud and data strategies aligned with business goals {" "}
            </li>
            <li className="default">
              Infrastructure, Data center(onpremise, colocation and fully managed) and cloud solutions from different OEM, MSP, CSP and Hyperscalers{" "}
            </li>
            <li className="default">
              Solution design for virtualization, private/hybrid/multi cloud, containers, software defined solutions and services{" "}
            </li>
            <li className="default">
              Expert support for migration, database, data science, AI/ML and various enterprise applications{" "}
            </li>
            <li className="default">
              Sizing Optimal infrastructure for variety of workloads and solutions{" "}
            </li>
            <li className="default">
              Training support to develop skills, labs, research and project development{" "}
            </li>
            <li className="default"> Technical enablement for Independent Software Vendors(ISVs) </li>
          </ul>
          <img src="https://encyc-cdn.s3.amazonaws.com/public_asset/png/encycdata/marketing/admin/India/belldiary/brand/Encycdata-Multi-Cloud-Marketplace.PNG" alt="Girl in a jacket" width="1000" height="600"></img>
        </div>
      </Container>
    </div>
  );
}

export default CloudMarketPlace;
