import React from "react";
import "./contactUsFooter.css";

function ContactUsFooter() {
  return (
    <div>
      <div className="footerWrapper">
        <h1 className="ContactUsHeading">Contact Us</h1>
        <p className="ContactUsSubheading">
          Please drop a note to us if you are interested to explore our products and services and to partner with Encycdata.
        </p>

        <button className="btn btn-dark btn-rounded" >
          <a href="mailto:admin@encycdata.com">Contact Us</a>
        </button>
      </div>
    </div>
  );
}

export default ContactUsFooter;
