// STATIC DATA

import {
  FaCloudUploadAlt,
  FaCode,
  FaDatabase,
  FaDesktop,
  FaPhabricator,
  FaServer,
} from "react-icons/fa";

const company = {
  ourStory: {
    text: "Encycdata is a privately held software development company founded in 2022 having registered office in Sitheri, an Agriculture region in Tamilnadu, famous for curry leaves, cotton, cassava(tapioca), etc. Miss Sitheri is a beautiful lake and village on the bank of the great sage Vasistar river and Chinnaru and accompanied by her sister Lake Periyeri on the other side. It is about 7km from Salem-Chennai National Highway. Encycdata business focus caters into information technology, research, education, training, rural development, and economic empowerment. Mr. Mouleeswaran and Mrs. Suseela are proprietors, a Farmar family, started it based on the style and culture of diversified daily wagers who do a variety of works together at different farms to nurture agriculture.",
    title: "Our Story",
  },
  ourVision: {
    text: "To be recognized as the state-of-the-art company for providing technology, data and people driven solutions and services.",
    title: "Our Vision",
  },
  ourMission: {
    text: "Develop best software products, innovative solutions and processes for users, business, and technology to solve problems across industries.",
    title: "Our Mission",
  },
  coreValues: [
    {
      title: "Client Value",
      text: "Obsessed with quality, openness, and satisfaction",
    },
    {
      title: "User Value",
      text: " Respect and trust data privacy of every user, clients and partners leveraging our platforms.",
    },
    {
      title: "Data Driven",
      text: "Decision and actions based on experience and what matters best rather than most.",
    },
    {
      title: "Leadership",
      text: "Practice and apply co-leadership, shared responsibility, diversity and inclusion and to be an example within the company first.",
    },
    {
      title: "Innovation",
      text: "Lead with innovation for inhouse products, client and partner solutions and services.",
    },
    {
      title: "People Centric",
      text: "Adopt diversity first, humanity first and remote work first",
    },
  ],
  goal: {
    title: "Goal",
    data: [
      "Develop an event marketplace with new way of audience engegement",
      "Develop a platform for self-study and collaboration focused on diversity and inclusion",
      "Research, develop and build multi-cloud solutions",
      "Develop a partner ecosystem for GTM, joint solutions and product integrations",
    ],
  },
  workCulture: {
    title: "Work Culture",
    text: "Talents are everywhere and people can learn things if opportunities are provided and guided. The company completely believes in fully remote work culture and diversity and is open to work with businesses across the globe. We believe in people who are our roots and making them strong will help us realize our vision and we are in the beginning and building a strong foundation, processes, team, and partner community. Encycdata adopted co-ownership and shared responsibility models, it would be a great place for anyone to work on different technologies and products. We are committed to leverage talent across diversified groups and economically challenged communities by providing training, shadowing, and mentoring to improve their skills and opportunities to strengthen our vision.",
    img: "",
  },
};

const home = {
  carousel: [
    {
      text: "First-ever platform to develop brand for Students, Education Institutions and Professionals",
      buttonLink: "",
    },
    {
      text: "Developing Enterprise Business Capabilities is a key to bring exposure to students and faculties",
      buttonLink: "",
    },
    {
      text: "A Unified platform to Plan, Explore, Learn and Track to empower beyond your professional life",
      buttonLink: "",
    },
    {
      text: "Self-Learning  and self development is vital continuous process in career transformation.",
      buttonLink: "",
    },
    {
      text: "Data privacy matters most! All users have right to know how our businesses use it",
      buttonLink: "",
    },
  ],
  whatWeDo: {
    title: "What We Do",
    data: [
      {
        title: "Product Development",
        text: "We develop software products to address many business challenges leveraging innovative ideas, patents, and technologies. Our core strength is our in-house products, and we learn many things during the product life cycle to improve ourselves and to exploit technologies and architectures. It is basis for recommending proven solutions to our customers.",
      },
      {
        title: "Research",
        text: "We passion on deep dive into many business challenges and come up with solutions focused on the terms legitimate, openness, data privacy, education, diversity, and economic empowerment. Our productize approach is a pillar which drives business and technology and we thrilled to see how different hardware, software, and solutions performs at scale.",
      },
      {
        title: "Consulting and Services",
        text: "We are open to help businesses to adopt our talents, experiences, partner community and best practices to get mutual benefits. Our product and research expertise together with shared responsibility culture can provide quality outcome for customer requirements. We are open for partnership and joint solutions to address market requirements.",
      },
    ],
  },
  whoWeAre: {
    sectionTitle: "Who We Are",
    title: "We are Solution Makers and Consultants",
    text: "Encycdata is a startup company working on developing collaboration, education and other solutions. Developing software products and providing technology consulting services are our core business. We believe in people across diverse communities and our inhouse product development leverages talents worldwide. We adopted people and diversity first philosophy, and we are open to work with organizations globally meet our vision.",
  },
  howWeDo: {
    sectionTitle: "How We Do",
    title: "We are a data and technology driven company",
    text: "Our products are designed to explore and address user, business and technology challenges inclined towards data privacy and scalability. We like and evaluate many technologies and we do research and experiment our workloads across on-premises and multi-cloud with polyglot design and architectures. Our innovation and best practices from this practice are the proof to help our customers to adopt technology and solutions appropriately.",
  },
  news: {
    title: "News",
    data: [
      {
        date: "01 Jan 2024",
        text: "Encycdata BellDiary platform beta is launched for students, faculties and professionals to publish project, product and solution contents across technologies and industries.",
      },
      {
        date: "15 Jul 2023",
        text: "Encycdata is open to work with many partners for technology, service, event management and DEIB empowerment.",
      },
      {
        date: "26 Aug 2022",
        text: "Education Institutes are requested to complete Students Career Orientation Challenges Survey",
        link: " https://forms.gle/iRSU9gM3v1SPKCwF7",
      },
      {
        date: "02 Oct 2022",
        text: "Encycdata partnered with Lumen technologies to provide datacenter solutions and services",
        
      },
      {
        date: "20 Oct 2022",
        text: "Encycdata partnered with Interprefy to provide Event management and interpretor services",
        
      },
    ],
  },
  events: {
    title: "Partner Events",
    data: [
      {
        date: "",
        text: "Datascience Salon Webinars",
        link: "https://www.youtube.com/@DataScienceSalon",
      },
      {
        date: "",
        text: "Big Data and Machine Learning in Trading - Quantinsti",
        link: "https://blog.quantinsti.com/tag/webinars/",
      },
      {
        date: "",
        text: "Explore SUSE Events",
        link: "https://www.suse.com/events/",
      },
      {
        date: "",
        text: "Watch vmware EXPLORE on-demand",
        link: "https://www.vmware.com/explore/us.html?src=WWW_us_VMW_gIhUrX07jvmSRPwVGcTF&int_cid=7012H000001lIFq",
      },
    ],
  },
  learn: {
    title: "Learn",
    data: [
      {
        text: "Encycdata Bell Diary: How to develop your personal brand for students and education institutes?",
        link: "https://www.youtube.com/channel/UCD5x755KIQGwz7AaS2DPF7g",
      },
      {
        text: "Encycdata Bell Diary: How to create positive perspective of your students and institutions?",
        link: "https://www.youtube.com/channel/UCD5x755KIQGwz7AaS2DPF7g",
      },
      {
        text: "Encycdata Bell Diary: How to develop Enterprise Business capabilities during education journey?",
        link: "https://www.youtube.com/channel/UCD5x755KIQGwz7AaS2DPF7g",
      },
      {
        text: "Encycdata Bell Diary: New way of Audience engagement ",
        link: "https://www.youtube.com/channel/UCD5x755KIQGwz7AaS2DPF7g",
      },
    ],
  },
};

const services = {
  ConsultingServices: {
    title: "IT Consulting Services",
    text: "Encycdata can help any business to achieve digital transformation journey. We have people, process and technology expertise and partner ecosystem  to address challenges in adopting distruptive technologies and achieve Time-To-Market objectives. We offer the following services, ",
    serviceList: [
      {
        text: "IT Strategy and Digital Transformation ",
      },
      {
        text: "Product Management and Development  ",
      },
      {
        text: "Technology and Architecture ",
      },
      {
        text: "Software, Platform and Solution Development ",
      },
      {
        text: "Application modernization  ",
      },
      {
        text: "Infrastructure and cloud solutions ",
      },
    ],
  },
  talkToUs: {
    title: "Talk To Us",
    text: "Do you want to validate and build your applications like our scalable platform for your requirements? Please contact us.",
  },
  TrainingServices: {
    title: "EdTech Solutions and Services",
    text: "Encycdata Bell Diary is a unique learning platform for students, teachers and professionals for self development and career tranformation.",
    serviceList: [
      {
        text: "Role based learning for any specialization",
      },
      {
        text: "Realtime Project based learning ",
      },
      {
        text: "Instructor Led learning ",
      },
      {
        text: "Mentorship ",
      },
      {
        text: "Softskill development ",
      },
      {
        text: "Entrepreneurship ",
      },
    ],
  },
  languages: {
    title: "Langauges",
    technologies: ["Java", "C#", "Python"],
  },
  frameworks: {
    title: "Frameworks",
    technologies: ["Hibernate", "Spring Boot", "Node.js"],
  },
  mobile: {
    title: "Mobile",
    technologies: ["IOS", "Android", "HTML5"],
  },
  web: {
    title: "Web",
    technologies: ["React", "Vue"],
  },
  DatabaseManagement: {
    title: "Database Management",
    technologies: ["Oracle", "Cassandra", "MariaDB", "Elastic"],
  },
  cloud: {
    title: "Cloud",
    technologies: ["AWS", "GCP", "Azure","VMware","Cloud Native", "Multi-Cloud"],
  },
  deliveryPartners: {
    title: "Our Technology and Service Delivery Partners",
    text: "We are looking for service delivery partners and technology partners for our in-house products and to deliver for our customers. Please drop a note to us at admin@encycdata.com",
  },
  EventManagementServices: {
    title: "Event Management Services",
    text: "Encycdata provides event management platform and solution to engage audience in unique way. We partnered with leading event management providers to run events for any scale.",
    serviceList: [
      {
        text: "Virtual Events ",
      },
      {
        text: "Interpretor services ",
      },
      {
        text: "Job Fairs  ",
      },
      {
        text: "Networking and community development",
      },
      {
        text: "Marketplace",
      },
    ],
  },
  StaffingServices: {
    title: "Staffing and Reqruitment Services",
    text: "Encycdata provides talented and experienced resources for all technologies. We work with companies specialized in diffrent technologies, products and solutions.",
    serviceList: [
      {
        text: "Software Development ( Developer, Technical Lead, Business Analyst, Solution Architect, Technical Project Manager) ",
      },
      {
        text: "Infrastructure services (Managed Services,Engineer, System Architect, Cloud Architect, Support Engineer) ",
      },
      {
        text: "Quality Assurance (Manual, Automation and Performance Testing)  ",
      },
      {
        text: "Security Services (Seceon, Cyble, F5, Palo Alto Networks, Cloud Security)",
      },
      {
        text: "Consulting Services (IT Strategy, Solutions, Data Strategy, AI Strategy, Technology Strategy) ",
      },
      {
        text: "Executive and CxO Advisory Services (Business, Technology, Partners) ",
      },
    ],
  },
  infrastructure: {
    title: "Infrastructure Solutions",
  },
  analytics: {
    title: "Analytics",
  },
  security: {
    title: "Security",
  },
};

const products = {
  description:
    "Digital transformation is for empowering and nurturing our livelihood but nature is precious than anything. Encycdata is proud of naming the projects nature centric. ",
  projectRiceShores: {
    title: "Project: Rice Shores",
    text: "Planning is the basis for learning and finding right information to explore knowledge beyond our professional can make life easier. Project Rice Caves targeted to address different users and business requirements.",
    list: [
      "Students are good at books but not every student gets an opportunity to meet experts in their field of interest. Need some guidance and right information to succeed.",
      "Businesses need a collaborative solution which can attract right audience and generate leads.",
      "Professionals are good at their career but biased in understanding what is happening in the industry and preparing themselves for market and technology changes.",
      "Set a goal, plan and finishing before deadline is an art",
      "Plenty of things to learn but interests matter most.",
    ],
  },
  projectCottonShores: {
    title: "Project: Cotton Caves",
    text: "Education is basis for everything and how we learn is important aspect to produce right outcome. Online learning is adopted in the industry mostly and having a teacher/tutor always with learner is good choice.",
    list: [
      "Self-learning is great way to acquire knowledge, and it must be practiced from childhood days",
      "Achieve online learning experience comparable with in-class experience",
      "Provide right tools and platform to know how to do it",
      "Learning through gamification can ease with lot of fun",
    ],
  },
  projectCoirSpace: {
    title: "Project: Neem-Coir Space",
    text: "Many social and collaborative platforms are available to engage and communicate in our known circle. World has choice of different cultures and traditions. Making new relations and finding interests among them and sharing things can betterment in new ways.",
    list: [
      "Just feel like you have relations around the world",
      "Children are new roots and can  connect with relatives everywhere",
      "Meet them and plan for get-together, events, tour, etc.",
      "Share your experiences, collaborate and engage",
    ],
  },
};

const research = {
  description:
    "Encycdata vision is to be an example and adopt things right to provide balanced priority to businesses, users and technology.  We are committed to address many challenges and data driven research helps us to come up with new solutions.",
  ruralAndEconomic: {
    title: "Rural and Economic Development",
    list: [
      "•	Knowledge and talent are everywhere, and little support and adjustment can make big change",
      "•	Many Remote working jobs proved that location does not make much difference",
      "•	Small scale Agricultural Entrepreneurship can be nurtured with Training, mentoring and shadowing",
      "•	Adopt Digital transformation to develop strong community",
    ],
  },
  diversityWorkplace: {
    title: "Diversity and Shared Responsibility at Workplace",
    list: [
      "•	Practical inclusion is no more than better exercise than anything.",
      "•	It is simple to do, and it is not a marketing stuff!!",
      "•	Understanding and sharing common vision from executive to entry level is a key.",
    ],
  },
  multiCloudAdoption: {
    title: "Digital Transformation and Technology Trends and Challenges",
    list: [
      "•	Growing service providers, technologies and data brings challenge for any business to set strategy",
      "•	Competition and dynamics of technology providers slowdown adoption and agility",
      "•	On-premises and public cloud are not enemies and devising right approach would yield business benefits",
      "•	Validated solutions, technical enablement and partner ecosystem makes the journey smooth.",
    ],
  },
};

const socialMedia = {
  linkedin: "https://www.linkedin.com/company/encycdata/",
  facebook: "https://www.facebook.com/Encycdata-111081384945470",
  twitter: "https://twitter.com/encycdata",
  youtube: "https://www.youtube.com/channel/UCD5x755KIQGwz7AaS2DPF7g",
  github: "https://github.com/encycdata",
};

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Angola Anguilla",
  "Antarctica",
  "Antigua Barbuda",
  "Armenia",
  "Aruba",
  "Australia",
  "Azerbaidjan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia-Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "Former Czechoslovakia",
  "Former USSR",
  "France",
  "France (European Territory)",
  "French Guyana",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe (French)",
  "Guam (USA)",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Heard McDonald Is",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique (French)",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldavia",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "Neutral Zone",
  "New Caledonia (French)",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Is",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Island",
  "Poland",
  "Polynesia (French)",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion (French)",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts & Nevis Anguilla",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Tome and Principe",
  "Saint Vincent & Grenadines",
  "Samoa",
  "San Marino",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovak Republic",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Suriname",
  "Svalbard Jan Mayen Is",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Tadjikistan",
  "Taiwan",
  "Tanzania",
  "Thailand",
  "the Democratic Republic",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks Caicos Is",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (USA)",
  "Wallis and Futuna Is",
  "Western Sahara",
  "Yemen",
  "Zaire",
  "Zambia",
  "Zimbabwe",
];

export const serviceTable = [
  {
    title: "Web Development",
    technologies: ["React", "React Native","Vue", "Wordpress"],
    icon: <FaCode />,
  },
  {
    title: "Backend Development",
    technologies: ["Java","Spring Boot", "NodeJS","Python", ".NET"],
    icon: <FaDesktop />,
  },
  {
    title: "IT Automation",
    technologies: ["DevOps", "Ansible", "Jenkins","Terraform"],
    icon: <FaServer />,
  },
  {
    title: "Infrastructure Solutions",
    technologies: [
      "Data center", "colocation",
      "Consolidation", "Storage",
      "Private Cloud",
      "Virtualization", "Kubernetes",
      "Edge Computing", "Accelerators", "Managed Services"
    ],
    icon: <FaPhabricator />,
  },
  {
    title: "Cloud Solutions",
    technologies: ["AWS", "GCP", "Azure", "Oracle","Akamai Linode"],
    icon: <FaCloudUploadAlt />,
  },
  {
    title: "Database ",
    technologies: ["Oralce", "PostgreSQL", "MongoDB","Cassandra", "Elastic search","Neo4j", "Redis"],
    icon: <FaDatabase />,
  },
  {
    title: "Data science and Machine Learning",
    technologies: ["Hadoop", "Spark", "python", "Qlik", "Kafka", "Flink", "PyTorch", "TensorFlow", "Keras", "Generative AI", "Chat-GPT", "Google Vertex AI", "AWS Sagemaker","MLOps"],
    icon: <FaDatabase />,
  },
  {
    title: "Security ",
    technologies: ["ZTNA", "Cloud Security", "Application Security", "SOAR", "SIEM","Log Analytics"],
    icon: <FaDatabase />,
  },
  {
    title: "Workloads ",
    technologies: ["Web","Virtual Desktop", "Database", "HPC","In-Memory", "Trading", "Graphics", "AIML", "Quantum Computing"],
    icon: <FaDatabase />,
  },

];

export { company, home, services, products, research, countries, socialMedia };

// Project: Coir Space
