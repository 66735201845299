import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BellDairyText from "./BellDairyText";
import BellDairyImage from "./BellDairyImage";

function BellDairy() {
  return (
    <Container
    // style={{
    //   backgroundColor: "white",
    // }}
    >
      <Row>
        <Col md={8}>
          <BellDairyText />
        </Col>
        <Col md={4}>
          <BellDairyImage />
        </Col>
      </Row>
    </Container>
  );
}

export default BellDairy;
