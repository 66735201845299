import React, { useState } from "react";

// import { home } from "../../data";

const NewsFlash = () => {
  let [news, setNews] = useState(0);

  const timeout = setInterval(changeNews, 15000);
  function changeNews() {
    if (news === 0) {
      setNews(news + 1);
      clearInterval(timeout);
    } else {
      setNews(news - 1);
      clearInterval(timeout);
    }
  }
  return (
    <div
      style={{
        background: "#547069",
        padding: "12px",
        color: "#EED811",
      }}
      className="text-center newsflash"
    >
      <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
        {/*`${home.news.data[news].text}`*/}
        <a
          href="https://forms.gle/5H2sDGrJ7ZXQQpsw5"
          target="_blank"
          rel="noreferrer"
        >
          Dear Students, Faculties and Professionals!! Encycdata BellDiary is live!! Engage with us to publish your projects, research papers, product marketing and blogs!
        </a>
      </p>
    </div>
  );
};

export default NewsFlash;
