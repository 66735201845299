import React from "react";

import Brief from "..//Brief/Brief";
import BellDairy from "../BellDairyText/BellDairy";
import CloudMarketPlace from "../CloudMarketPlace/CloudMarketPlace";
import ProjectNeem from "../ProjectNeem/ProjectNeem";

import { BriefText } from "../Cardlinks";

import "./Products.css";

const Products = () => {
  return (
    <div className="WrapperForProducts">
      <Brief BriefText={BriefText[1]} />
      <BellDairy />
      <CloudMarketPlace />
      <ProjectNeem />
    </div>
  );
};

export default Products;
