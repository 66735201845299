import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaBook, FaCalendar, FaMicrochip } from "react-icons/fa";
import BlogCard from "../Blogs/BlogCard";
import { blogList } from "../Blogs/Blogdata";
import BlogsHeader from "../Blogs/BlogsHeader";
import { HeaderText } from "../Cardlinks";

function Events() {
  const tech = useRef(null);
  const event = useRef(null);
  const bellDairy = useRef(null);

  const scrollToSection = (elementRef) => {
    var headerOffset = 60;
    var elementPosition = elementRef.current.offsetTop;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ backgroundColor: "#e9f4fd" }}>
      <BlogsHeader HeaderText={HeaderText[2]} />
      <div className="sticky">
        <Container fluid className="ButtonContainer">
          <Row className="ButtonPostion">
            <Col>
              <button
                onClick={() => {
                  scrollToSection(tech);
                }}
                className="blogButton-blue blogButton"
              >
                Technology{" "}
                <FaMicrochip color="#3ec1f4" style={{ marginLeft: "10px" }} />
              </button>
            </Col>
            <Col>
              <button
                onClick={() => {
                  scrollToSection(bellDairy);
                }}
                className="blogButton blogButton-orange"
              >
                Encydata Bell Diary
                <FaBook color="orange" style={{ marginLeft: "10px" }} />
              </button>
            </Col>
            <Col>
              <button
                onClick={() => {
                  scrollToSection(event);
                }}
                className="blogButton blogButton-green"
              >
                Events
                <FaCalendar color="green" style={{ marginLeft: "10px" }} />
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="blogWrapper" ref={tech}>
        <p className="blogTitle">Technology Stories</p>
        <Container>
          <Row>
            {blogList.map((list) => (
              <Col
                style={{
                  justifyContent: "center",
                  padding: "10px",
                }}
                sm={6}
                md={4}
              >
                <BlogCard link={list} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="blogWrapper" ref={bellDairy}>
        <p className="blogTitle">Encycdata Bell Diary Stories</p>
        <Container>
          <Row>
            {blogList.map((list) => (
              <Col
                style={{
                  justifyContent: "center",
                }}
                sm={6}
                md={4}
              >
                <BlogCard link={list} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="blogWrapper" ref={event}>
        <p className="blogTitle">Event Management Stories</p>
        <Container>
          <Row>
            {blogList.map((list) => (
              <Col
                style={{
                  justifyContent: "center",
                }}
                sm={6}
                md={4}
              >
                <BlogCard link={list} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Events;
