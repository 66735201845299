import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VscTriangleDown } from "react-icons/vsc";

export default function DropDown({ title, items, navCloseOnClick }) {
  // const [show, setShow] = useState(false);
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };
  const icon = (
    <div>
      Company <VscTriangleDown />
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 0,
      }}
    >
      <NavDropdown title={icon}>
        {items.map((item) => {
          const { name, link } = item;
          return (
            <NavDropdown.Item key={name}>
              <Link onClick={navCloseOnClick} to={link}>
                {name}
              </Link>
            </NavDropdown.Item>
          );
        })}
      </NavDropdown>
    </div>
  );
}
