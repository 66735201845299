import React from "react";
import "./Blogs.css";

function BlogsHeader({ HeaderText }) {
  return (
    <div>
      <div className="blogHeaderWrapper">
        <h1 className="blogHeading">{HeaderText.heading}</h1>
        <p className="blogSubheading">{HeaderText.subheading}</p>
      </div>
    </div>
  );
}

export default BlogsHeader;
