export const blogListEvents = [
  {
    BlogSubCategory: "Audience Engagement",
    BlogTitle: "Unique way of audience engagement through Bell Diary Platform",
    Authors: ["Encycdata Event Management Team"],
    Date: "October 18, 2022",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      "Role based audience engagement is necessary in the agile and trasforming world and self development is a considerable focus for anyone to get involved",
    BlogSourceLink:
      "https://www.belldiary.com/technology/information-technology/blogs",
    Tags: ["Backend"],
  },
  {
    BlogSubCategory: "Event Management",
    BlogTitle: "Encycdata partnertd with Interprefy to deliver event solutions",
    Authors: ["Encycdata Event Management Team"],
    Date: "October 21, 2022",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      "Encycdata has partnered with Interprefy to provide event management solutions for our clients along with interpretor services.",
    BlogSourceLink:
      "https://www.belldiary.com/technology/information-technology/blogs",
    Tags: ["Cloud", "Sizing", "Capacity Planning"],
  },
];
