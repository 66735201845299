import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import "./BlogCard.css";
import { CardContent } from "@mui/material";

function BlogCard({ link }) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Card
      className="cards"
      sx={{
        padding: "5px",
        display: "table-cell",
      }}
    >
      <CardMedia
        style={{
          height: 150,
          padding: "10px",
          objectFit: "contain",
        }}
        component="img"
        alt={link.BlogSubCategory}
        image={link.BlogBanner}
      />
      <CardContent className="cardContent">
        <h6 className="CardDetails">
          {link.Authors.map((name) => (
            <h6 className="CardDetails" style={{ display: "inline" }}>
              {name} |
            </h6>
          ))}
          {link.Date}
        </h6>
        <h5
          className="texttoLeft"
          style={{
            padding: "2px",
          }}
        >
          {link.BlogTitle}
        </h5>
        <p className="decriptionText">{link.Description}</p>
      </CardContent>
      <CardActions
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          size="small"
          className="learnMore"
          onClick={() => openInNewTab(link.BlogSourceLink)}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default BlogCard;
