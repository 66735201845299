import React from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./MarketplaceCard.module.css";
import { COMPANY_EMAIL } from "../../../../constants/constants";

function MarketplaceCard({ cardData }) {
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/marketplace/${cardData.id}`)}
    >
      <div className={styles.topSection}>
        <div className={styles.title}>{cardData.Name}</div>
        <div className={styles.size}>( {cardData.Size} )</div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.summary}>{cardData.Summary}</div>
        <div className={styles.price}>{cardData.Pricing}</div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button1}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`mailto:${COMPANY_EMAIL}`);
            }}
          >
            Buy
          </button>
          <button
            className={styles.button2}
            onClick={(e) => {
              e.stopPropagation();
              window.open(cardData?.SolutionDesignLink);
            }}
          >
            Solution Design
          </button>
        </div>
        <div className={styles.heading}>Technologies</div>
        {cardData.Technology.map((technology) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCheck size={10} color="#808080" />
            <div className={styles.item}>{technology}</div>
          </div>
        ))}
        <div className={styles.heading}>Services</div>
        {cardData.Services.map((services) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCheck size={10} color="#808080" />
            <div className={styles.item}>{services}</div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <img src={cardData.Partner.Logo} className={styles.logo} alt="" />
        <div className={styles.vendor}>{cardData.Partner.Vendor}</div>
      </div>
    </div>
  );
}

export default MarketplaceCard;
