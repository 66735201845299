import React from "react";
import "./ContactUsHeader.css";

function ContactUsHeader() {
  return (
    <div>
      <div className="HeaderWrapper">
        <p className="ContactUsSubheading">
          Do you want to validate and build your applications like Encycdata
          scalable platforms for your requirements?
        </p>

        <button className="btn btn-dark btn-rounded">
          <a href="mailto:admin@encycdata.com">Contact Us</a>
        </button>
      </div>
    </div>
  );
}

export default ContactUsHeader;
