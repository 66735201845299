import React from "react";

import HowWeDo from "../../container/howwedo/HowWeDo";
import WhatWeDo from "../../container/whatwedo/WhatWeDo";
import Hero from "../hero/Hero";

const Home = () => {
  return (
    <div
      style={{
        backgroundColor: "#e9f4fd",
      }}
    >
      <Hero />
      <WhatWeDo />
      <HowWeDo />
    </div>
  );
};

export default Home;
