import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";

import styles from "./Services.module.css";
import "./Services.css";

import { services, serviceTable } from "../../data";
import ContactUsHeader from "../ContactUsHeader/ContactUsHeader";
import ContactUsFooter from "../contactUsFooter/ContactUsFooter";

const Services = () => {
  return (
    <div>
      <ContactUsHeader />
      <div className="serviceWrapper">
        <div className={`${styles.container} `}>
          <div>
            <h3 className="serviceHeading">
              {services.ConsultingServices.title}
            </h3>
            <p className="service-subheading">
              {services.ConsultingServices.text}
            </p>
            <ul>
              {services.ConsultingServices.serviceList.map((title) => (
                <li className="liServiceStyle">{title.text}</li>
              ))}
            </ul>
          </div>
          {/* 
      <div className={styles["services__talktous-banner"]}>
        <h4>{services.talkToUs.text}</h4> */}
          {/* <a href="#company">
          {" "}
          <Button variant="outline-dark">{services.talkToUs.title}</Button>
        </a> */}
          {/* </div> */}

          <div className={styles["services__technologies-card"]}>
            <Row xs={1} sm={2} md={3} className="technologyTable">
              {serviceTable.map((list) => (
                <Col className="technologyList">
                  <h3>
                    {list.icon} {list.title}
                  </h3>
                  <Row className="technologyListview">
                    {list.technologies.map((tools) => (
                      <li>{tools}</li>
                    ))}
                  </Row>
                </Col>
              ))}
            </Row>

            {/* 3rd column */}
            {/*
            <Row xs={1} md={3}>
              <Col className={styles["services__languages"]}>
                <h3>
                  <FaMobileAlt className={styles.icons} /> IT Automation
                </h3>
            */}
            
            {/*
            <ul>
              {services.mobile.technologies.map((l, i) => (
                <li key={i}>{l}</li>
              ))}
            </ul>
            */}
            {/*
              </Col>
              <Col className={styles["services__languages"]}>
                <h3>
                  <FaMobileAlt className={styles.icons} /> Analytics
                </h3>
                
            <ul>
              {services.mobile.technologies.map((l, i) => (
                <li key={i}>{l}</li>
              ))}
            </ul>
            */}
                            {/*
              </Col>
              <Col className={styles["services__languages"]}>
                <h3>
                  <FaMobileAlt className={styles.icons} /> Security
                </h3>
              <ul>
               {services.mobile.technologies.map((l, i) => (
                <li key={i}>{l}</li>
              ))} 
            </ul> 
              </Col>
            </Row>*/}

          </div>


          <div>
            <h3 className="serviceHeading">
              {services.StaffingServices.title}
            </h3>
            <p className="service-subheading">
              {services.StaffingServices.text}
            </p>
            <ul style={{ marginBottom: 0 }}>
              {services.StaffingServices.serviceList.map((title) => (
                <li className="liServiceStyle">{title.text}</li>
              ))}
            </ul>
          </div>
          
          <div>
            <h3 className="serviceHeading">
              {" "}
              {services.TrainingServices.title}
            </h3>
            <p className="service-subheading">
              {services.TrainingServices.text}
            </p>
            <ul>
              {services.TrainingServices.serviceList.map((title) => (
                <li className="liServiceStyle">{title.text}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="serviceHeading">
              {services.EventManagementServices.title}
            </h3>
            <p className="service-subheading">
              {services.EventManagementServices.text}
            </p>
            <ul style={{ marginBottom: 0 }}>
              {services.EventManagementServices.serviceList.map((title) => (
                <li className="liServiceStyle">{title.text}</li>
              ))}
            </ul>
          </div>

          {/* <p className="text-center mt-3"> */}
          {/* To become a partner and register for partner programs, Please contact
        Dataring @
        <a
          style={{ fontWeight: "bold", color: "#2DB3BA" }}
          href="mailto:admin@encycdata.com"
        >
          admin@encycdata.com 
        </a>*/}
          {/* </p> */}
        </div>
      </div>
      <ContactUsFooter />
    </div>
  );
};

export default Services;
