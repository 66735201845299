import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  FaLinkedinIn,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaGithub
} from "react-icons/fa";
import styles from "./Footer.module.css";
import { socialMedia } from "../../data";

const date = new Date();
const currentYear = date.getFullYear();

const { linkedin, facebook, twitter, youtube, github } = socialMedia;

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#e9f4fd" }}>
      <Row className={`${styles.footer}`}>
        <Col md={12} className={styles["footer__icons"]}>
          <a href={facebook} target="_blank">
            <FaFacebookF />
          </a>
          <a href={twitter} target="_blank">
            <FaTwitter />
          </a>
          <a href={linkedin} target="_blank">
            <FaLinkedinIn />
          </a>
          <a href={youtube} target="_blank">
            <FaYoutube />
          </a>
          <a href={github} target="_blank">
            <FaGithub />
          </a>
        </Col>
        <Col md={12} className={styles["footer__legaldetails"]}>
          
          <a className={styles["foot__legalText"]} href="https://sites.google.com/encycdata.com/encycdata-website-terms-of-use/home" target="_blank">
            Terms of Use
  </a> 
          |
          <a className={styles["foot__legalText"]} href="https://sites.google.com/encycdata.com/encycdata-privacy-policy/home
" target="_blank">
            Privacy Policy
          </a>
          |
          <a className={styles["foot__legalText"]} href="https://sites.google.com/encycdata.com/encycdata-privacy-policy/home
 " target="_blank">
            Do Not Sell My Information
          </a>
        </Col>
        <p className={styles["footer__copyright-text"]}>
          Copyright © {currentYear} Encycdata and/or its Affiliates, All Rights
          Reserved.
        </p>

        {/* <Col md={12} className={styles["footer__copyright-text"]}>
        <p>
          Copyright © {currentYear} Encycdata and/or its Affiliates, All Rights
          Reserved.
        </p>
      </Col> */}

        <Col xs={12} sm={6} md={4} className={styles["footer__pages"]}>
          {/*
        <a href="#terms">Terms </a>
        <strong>.</strong>
        <a href="#privacy"> Privacy</a>
        */}
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
