export const blogListTechnology = [
  {
    BlogSubCategory: "Development",
    BlogTitle: "Cassandra & SpringBoot Best Practices",
    Authors: ["Mouleeswaran Ramasamy", "Atul Kumar"],
    Date: "October 18, 2022",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      "Cassandra is a distributed object store and deliver exceptional performance for variety of workloads. SpringBoot integration for cassandra provides lot of options to get better performance",
    BlogSourceLink:
      "https://www.belldiary.com/technology/information-technology/blogs",
    Tags: ["Backend"],
  },
  {
    BlogSubCategory: "Infrastructure",
    BlogTitle: "Right Sizing Your On-premise and Cloud Infrastructure",
    Authors: ["Encycdata Co-Engineering Team"],
    Date: "October 21, 2022",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      "Encycdata adopted hybrid cloud strategy and we size on-premise and public cloud infrastructure efficiently to achieve best out of both worlds",
    BlogSourceLink:
      "https://www.belldiary.com/technology/information-technology/blogs",
    Tags: ["Cloud", "Sizing", "Capacity Planning"],
  },
  {
    BlogSubCategory: "Enablement",
    BlogTitle: "Technical Enablement and Partner Success Strategy",
    Authors: ["Encycdata Executive Team"],
    Date: "October 18, 2022",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      "Technical Enablement is a key for setting up solid partner eco system and Encycdata success is driven by technology partners across globe.",
    BlogSourceLink:
      "https://www.belldiary.com/technology/information-technology/blogs",
    Tags: ["Backend"],
  },
];
