import React from "react";
import { Button } from "react-bootstrap";
import "./BellDairyText.css";

function BellDairyText() {
  return (
    <div className="WrapperForText">
      <ul className="ulstyle">
      <li className="blue">Encycdata EdTech Platform </li>
                <li className="yellow">Bell Diary </li>
        
      </ul>
      <p className="quote">formerly Project “Rice Shores” and “Cotton Caves”</p>
      <p className="Description1">
        Encycdata Bell Diary is a unified platform for students, faculties, educational
        institutions and professionals to develop skills and personal brand on different technical and managerial career paths.
      </p>
      <p className="Description2">
        Encycdata Bell Diary platform is for developing personal brand, understand enterprise business and apply role based
        learning  in the graduation and in the professional journey. Encycdata would work with educational institutions, students, faculties and professionals
        to guide and mentor to get exposure to professional journey and latest technologies relevant to curriculam and career aspirations.

        <br></br>
        The platform supports upskill on different career tracks across technologies and industries. 
      </p>
      <ul className="ulstyle">
        <div>

                  <Button size="lg" className="DemoButton">
            <a href="https://www.belldiary.com/" target="_blank" className="buttonText">
              Explore
            </a>
          </Button>
        </div>
        {/*}
        <Button size="lg" className="DemoButton">
          <a href="https://forms.gle/5Q4kfNgsYLeQNtcw6" target="_blank" className="buttonText">
            Survey
          </a>
        </Button>
  */}
  <img src="https://encyc-cdn.s3.amazonaws.com/public_asset/png/encycdata/marketing/admin/India/belldiary/brand/Interdiciplinary+Integrated+Learning+-+Engineering+Skill+Mapping.PNG" alt="Girl in a jacket" width="1000" height="600"></img>
      </ul>
    </div>
  );
}

export default BellDairyText;
