import React from "react";

function ProjectNeemImage() {
  const publicUrl = process.env.PUBLIC_URL;
  const iconlink = publicUrl + "projectneem/projectneem.png";
  return (
    <div>
      <img
        src={iconlink}
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "contain",
          paddingTop: "20vh",
          alignItems: "center",
        }}
        alt="Bell Dairy Logo"
      />
    </div>
  );
}

export default ProjectNeemImage;
