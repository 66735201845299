import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import styles from "./MarketplaceFilters.module.css";

const radioStyles = {
  "&.Mui-checked": {
    color: "#2db3ba",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
};

function MarketplaceFilters({
  selectedCategory,
  setSelectedCategory,
  marketplaceData,
}) {
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterTitle}>Solution & Services</div>
      <div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedCategory}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"All"}
              control={<Radio sx={radioStyles} />}
              label={"All"}
            />
            {marketplaceData.map((category) => (
              <FormControlLabel
                value={category.MainCategory}
                control={<Radio sx={radioStyles} />}
                label={category.MainCategory}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default MarketplaceFilters;
