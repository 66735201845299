export const HeaderText = [
  {
    heading: "Encycdata Blogs",
    subheading: "",
  },
  {
    heading: "Encycdata Testimonials",
    subheading: "Based on Experience and in house validation",
  },
  {
    heading: "Encycdata Events",
    subheading: "Based on Experience and in house validation",
  },
];

export const BriefText = [
  {
    heading:
      " Encycdata offers technology, business, services and event management partnerships to attain highest customer satisfaction and achieve mutual benefits",
  },
  {
    heading:
      "Digital transformation is for empowering and nurturing our livelihood but nature is precious than anything. Encycdata is proud of naming the projects nature centric.",
  },
  {
    heading: " Contact Us",
  },
];

const publicUrl = process.env.PUBLIC_URL;

export const Partners = [
  {
    id: "tech",
    heading: "Technology Partner",
    subheading:
      "Encycdata partners with broad set of technology partners, service providers and resellers across the globe to provide state of the art solutions, services and choices to our clients.",
    toLinks: [
      {
        iconlink: publicUrl + "partner/technology/d2iq.svg",
        alt: "d2iq",
      },
      {
        iconlink: publicUrl + "partner/technology/docker.svg",
        alt: "docker",
      },
      {
        iconlink: publicUrl + "partner/technology/elastic.svg",
        alt: "elastic",
      },
      {
        iconlink: publicUrl + "partner/technology/microsoft.svg",
        alt: "microsoft",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/business/treeptik/treeptik@2x.png",
      },
    ],
    background: "#f0f5ff",
  },
  {
    id: "cloud",
    heading: "Cloud  Partner",
    subheading:
      "Encycdata partners with major cloud providers, so you can run your applications on the infrastructure of your choice.",

    background: "white",
    toLinks: [
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/business/data-essential/data-essential@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/business/treeptik/treeptik@2x.png",
      },
    ],
  },
  {
    id: "business",
    heading: "Business Partner",
    subheading:
      "Encycdata forms alliances with a select group of system integrators, training and consulting companies, and resellers from across the globe to deliver premium services to your organization.",
    background: "#f0f5ff",
    toLinks: [
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/tech/jaeger/jaeger@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/business/data-essential/data-essential@2x.jpg",
      },
      {
        iconlink:
          "https://traefik.io/images/partners/business/treeptik/treeptik@2x.png",
      },
    ],
  },
];
