export const blogListBellDiary = [
  {
    BlogSubCategory: "BETA",
    BlogTitle:
      "Bell Diary Platform beta is live! Users are invited for content publishing!",
    Authors: ["Encycdata"],
    Date: "January 1, 2024",
    BlogBanner: "https://belldiary.com/assets/logo.abc492a3.png",
    Description:
      '"The First-ever Social platform" designed to empower Students, faculties and Education Institutions enterprise business capabilities',
    BlogSourceLink:
      "https://www.belldiary.com/",
    Tags: ["Backend"],
  },
];
